<template>
  <div class="choose-branch" v-if="showChooseBranch">
    <p>בחר סניף</p>
      <el-select style="width:270px;" v-model="selectedBranch" filterable placeholder="בחירת סניף">
          <el-option
            v-for="branche in branches"
            :key="branche"
            :value="branche"
          >
          </el-option>
      </el-select>
    <Button
      label="המשך להזמנה"
      style="width:260px; height:100px; margin:5px; font-size: 2rem;"
      class="p-button-warning"
      @click="handleShowNewOrder"
    />
  </div>

  <Dialog
    @hide="whenDialogClose"
    header="טופס הזמנת ביגוד"
    :style="{ width: '90%', height: '100vh' }"
    v-model:visible="showOrders"
  >
    <div class="container-bigud-order">
      <div class="wrapper">
        <div class="introduction">
          <h4>עובד רוסמן יקר/ה!</h4>
          <p>
            בהזמנתך הנך מאשר בזאת כי קיבלת לרשותך ביגוד ו/או הנעלה כשהוא חדש
            ושלם וללא כל פגם.
          </p>
          <p>
            ידוע לך כי קבלת הציוד הינה לטובת פעילותך עבור המעסיק והנך מצהיר
            ומתחייב להשתמש להתמש בציוד אך ורק לצורך פעילותך ועבודתך אצל המעסיק.
          </p>
          <p>
            באם הנך עובד חדש (עד 60 יום מתאריך תחילת עבודתך) אתה מתחייב להשיב את
            הציוד למעסיק עם סיום עבודתך אצלו מכל סיבה שהיא ו/או מיד עם דרישתו
            הראשונה כשהוא שלם ותקין, ללא כל פגם, למעט בלאי הנובע משימוש סביר
            ורגיל, בהתאם להוראות יצרן הציוד ו/או המעסיק.
          </p>
          <p>
            באם הציוד יינזק ו/או ייגנב ו/או ייעלם מכל סיבה שהיא, אחויב בסכום
            השווה לשווי ציוד חדש כפי ערכו ברכישה מהספק ו/או היבואן הראשי.
          </p>
          <p>התחייבותך הנ"ל הינן בלתי חוזרות.</p>
        </div>
        <div class="fields">
          <div class="field">
            <p>תאריך ההזמנה:</p>
            <input type="date" v-model="values.selectedDate" />
          </div>
          <div class="field">
            <p>ת"ז/דרכון עובד:</p>
            <InputText
              type="text"
              v-model="values.idNumber"
              style=""
              placeholder="תז דרכון"
            />
          </div>
          <div class="field">
            <p>שם העובד:</p>
            <InputText
              type="text"
              v-model="values.selectedFullName"
              style=""
              placeholder="שם פרטי ושם משפחה"
            />
          </div>
          <div class="field">
            <p>תפקיד העובד:</p>
            <Dropdown
              v-model="values.selectedRole"
              :options="roleList"
              placeholder="בחירת תפקיד"
              style=""
            />
          </div>
          <div class="field">
            <p>תיאור הפריט:</p>
            <MultiSelect
              v-model="values.selectedItems"
              :options="items"
              placeholder="תיאור הפריט"
              display="chip"
              style="margin-bottom: 1rem;"
            />
          </div>
          <div class="field">
            <strong><p v-if="values.selectedItems">נא למלא כמויות:</p></strong>
            <div class="items_qnt_wrapper">
              <template v-for="item in specificCurrentOrder" :key="item.barkod">
                <div class="items_and_qnt">
                  <div>
                    <strong
                      ><p>{{ item.ItemName }}</p></strong
                    >
                  </div>
                  <div class="stock">
                    <p>מלאי</p>
                    <small v-if="item.stock > 2" style="color:green">{{
                      item.stock
                    }}</small>
                    <small v-else style="color:red">{{ item.stock }}</small>
                  </div>
                  <div>
                    <Dropdown
                      v-model="item.quantity"
                      :options="quantities"
                      placeholder="כמות:"
                      style=""
                    />
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="field">
            <p>שם המזמין:</p>
            <InputText
              type="text"
              v-model="values.selectedOwner"
              style=""
              placeholder="שם פרטי ושם משפחה"
            />
          </div>
          <div class="field">
            <p>כתובת מייל לעדכון התהליך:</p>
            <InputText
              type="text"
              v-model="values.selectedEmail"
              style=""
              placeholder="הזן/י כתובת מייל לקבלת אישור"
            />
          </div>
          <div class="field">
            <p>חתימת העובד:</p>
            <SignaturePad idName="cnvinput" sketchName="sketchName" />
          </div>
        </div>
      </div>
      <div class="send-btn">
        <Button
          label="שלח טופס"
          style="width:40%; height:50px; font-size: 1.2rem; margin-bottom:0.5rem; margin: 5px;"
          @click="handleSubmit"
        />
      </div>

        <!-- JUST FOR MAIL -->
        <div class="form-email" id="form-email" v-show="false">
            <p style="width:100%; text-align:center; font-size:25px; margin:5px;">
                טופס הזמנת ביגוד חדשה סניף {{ selectedBranch }}
            </p>
            <table
                style="width:50%; border-collapse: collapse; font-size: 1.1rem; margin: 1rem 0;"
            >
                <tr style="background-color: #dddddd;">
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        תאריך ההזמנה:
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        {{values.selectedDate}}
                    </td>
                </tr>
                <tr>
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        ת"ז עובד:
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        {{ values.idNumber }}
                    </td>
                </tr>
                <tr style="background-color: #dddddd;">
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        שם העובד:
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        {{ values.selectedFullName }}
                    </td>
                </tr>
                <tr>
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        תפקיד העובד:
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        {{ values.selectedRole }}
                    </td>
                </tr>
                <template v-for="item in values.selectedItems" :key="item">
                    <tr style="background-color: #dddddd;">
                        <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                            שם פריט
                        </th>
                        <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                            {{item}}
                        </td>
                    </tr>
                </template>
                <tr>
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        שם המזמין:
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        {{ values.selectedOwner }}
                    </td>
                </tr>
                <tr>
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        כתובת מייל לעדכון התהליך:
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        {{ values.selectedEmail }}
                    </td>
                </tr>
                <tr>
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        חתימת העובד
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                      <img :src="canvasUrl" />
                    </td>
                </tr>
            </table>
            <p
                v-if="values.selectedItems"
                style="display: flex; gap: 15px; margin:1px; font-size:18px; font-weight:bold;"
                >
                פירוט כמויות:
            </p>
            <table style="width:50%; border-collapse: collapse; font-size: 1.1rem; margin: 1rem 0; border:1px solid #ddd; text-align: center;">
                <tr>
                    <th style="border: 1px solid #ddd; text-align: center; padding: 15px;">שם הפריט</th>
                    <th style="border: 1px solid #ddd; text-align: center; padding: 15px;">מלאי</th>
                    <th style="border: 1px solid #ddd; text-align: center; padding: 15px;">כמות</th>
                    <th style="border: 1px solid #ddd; text-align: center; padding: 15px;">מידה</th>
                </tr>
                <template v-for="item in specificCurrentOrder" :key="item">
                    <tr>
                        <td style="padding: 15px; border: 1px solid #ddd; text-align: center;">{{item.ItemName}}</td>
                        <td v-if="item.stock > 2" style=" padding: 15px; color:green; text-align:center; border: 1px solid #ddd;">{{item.stock }}</td>
                        <td v-else style=" padding: 15px; color:red; border: 1px solid #ddd; text-align:center;">{{item.stock}}</td>
                        <td style="padding: 15px; text-align:center; border: 1px solid #ddd;">{{ item.quantity }}</td>
                        <td style="padding: 15px; text-align:center; border: 1px solid #ddd;">{{item.selectedSizes}}</td>
                    </tr>
                </template>
            </table>
        </div>
    </div>
  </Dialog> 
</template>

<script>
/* eslint-disable */
import Dialog from "primevue/dialog";
import { computed, onMounted, ref } from "vue";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import MultiSelect from "primevue/multiselect";
import SignaturePad from '../../../signature/SignaturePad.vue'
import {alert} from '../../../../Methods/Msgs'
import {projectFirestore,projectFunctions} from '../../../../firebase/config'
import {increment} from '../../../../Methods/bigud_funcs'
import use_bigud_storage from '../../../../Methods/use_bigud_storage'
import {isDrawnOn} from '../../../../Methods/Signature_funcs'
import store from '../../../../store';


export default {
  emits:['close'],
  components: {
    Dropdown,
    InputText,
    Dialog,
    InputNumber,
    MultiSelect,
    SignaturePad
  },
  setup(_,{emit}) {
    const { url, filePath, uploadImage } = use_bigud_storage();

    const user_email = ref(computed(()=>{
      return store.getters.user.email
    }))
  
    const products = ref([]);
    const canvasUrl = ref(null)
   
    const values = ref({
      selectedDate: new Date().toISOString().slice(0,10),
      selectedFullName: null,
      idNumber: null,
      selectedRole: null,
      selectedItems: null,
      selectedOwner: null,
      selectedEmail: user_email.value,
    });

 
    const currentOrder=ref([])

    const itemsToUpdate=()=>{
      let data=[]
      if(values.value.selectedItems){
         const selectedItemArr=Object.values(values.value.selectedItems)
         data = currentOrder.value.filter(product =>
           selectedItemArr.includes(product.ItemName)
         );
      }
      return data
    }

    const specificCurrentOrder=ref(computed(()=>{
      let tempArr=[]
      if(values.value.selectedItems){
        values.value.selectedItems.forEach(item=>{
          tempArr.push(currentOrder.value.find(_item=>_item.ItemName==item))
        })
      }
      
      return tempArr
    }))

    const items = ref(
      computed(() => {
        const type = branchesTypes.value.find((branche) => branche.name == selectedBranch.value).type
        const data = products.value.filter(product =>
          product.selectedRole.includes(values.value.selectedRole) && product.selectedType == type
          || product.selectedRole.includes(values.value.selectedRole) && product.selectedType=='ללא סוג'
        );
        const tempArr = [];
        currentOrder.value=[];
        data.forEach(item => {
          if(item.visible=='true'){
            tempArr.push(item.ItemName);
            currentOrder.value.push({...item})
          }
        });
        return tempArr;
      })
    );


    const branches = ref([
      'מרכז',
      'רבמ"ד',
      'נווה שאנן',
      'כרמיאל',
      'הנביאים',
      'אליעזר',
      'סילבר',
      'שוק תלפיות',
      'שפרינצק',
      'רוטשילד',
      'סטוק סיטי',
      'הרצל',
      'הרצל סיטי',
      'בת גלים',
      'שוק 2',
      'ניצן',
      'ויצמן',
      'עפולה',
      'נשר',
      'רגבה',
      'ביוטי פארם חיפה',
      'ביוטי פארם נוף הגליל',
      'בת ים',
      'חדרה',
      'ירקות'
    ]);
    
    const branchesTypes = ref([
      {name:'מרכז',type:'מרקט'},
      {name:'רבמ"ד',type:'מרקט'},
      {name:'נווה שאנן',type:'מרקט'},
      {name:'כרמיאל',type:'מרקט'},
      {name:'הנביאים',type:'מרקט'},
      {name:'אליעזר',type:'מרקט'},
      {name:'סילבר',type:'מרקט'},
      {name:'שוק תלפיות',type:'מרקט'},
      {name:'שפרינצק',type:'מרקט'},
      {name:'רוטשילד',type:'מרקט'},
      {name:'סטוק סיטי',type:'סטוק סיטי'},
      {name:'הרצל',type:'מרקט'},
      {name:'הרצל סיטי',type:'מרקט'},
      {name:'בת גלים',type:'מרקט'},
      {name:'שוק 2',type:'מרקט'},
      {name:'ניצן',type:'מרקט'},
      {name:'ויצמן',type:'מרקט'},
      {name:'עפולה',type:'מרקט'},
      {name:'נשר',type:'מרקט'},
      {name:'רגבה',type:'מרקט'},
      {name:'בת ים',type:'מרקט'},
      {name:'ביוטי פארם חיפה',type:'ביוטי פארם'},
      {name:'ביוטי פארם נוף הגליל',type:'ביוטי פארם'},
      {name:'חדרה',type:'מרקט'},
      {name:'ירקות',type:'מרקט'},
    ]);

    const quantities = ref([
        '1','2','3','4'
    ])

    const roleList = ref(["קצבייה", "קופה", "שמירה", "מחסן", "מעדנייה","מנהל/סגן","סדרנות"]);

    const showChooseBranch = ref(true);
    const selectedBranch = ref("");
    const showOrders = ref(false);

    const whenDialogClose = () => {
      showChooseBranch.value = !showChooseBranch.value;
    };

    const handleShowNewOrder = () => {
      if (!selectedBranch.value) {
          alert('error','שגיאה','עליך לבחור סניף')
      } else {
        showOrders.value = !showOrders.value;
        showChooseBranch.value = !showChooseBranch.value;
      }
    };

    const validateOrderAmount = () => {
      const data = itemsToUpdate()
      for(let i=0; i<data.length; i++){
          if(!data[i].quantity){
              return false
          }
      }
      return true
    }

    const handleSubmit = async () => {
      const vluesList = {
        selectedDate: "תאריך",
        selectedFullName: "שם העובד",
        idNumber: "תעודת זהות",
        selectedRole: "תפקיד העובד",
        selectedItems: "תאור הפריט",
        selectedOwner: "שם המזמין",
        selectedEmail: "כתובת מייל"
      };
      let msg = "עליך למלא את השדות הבאים:\n";
      let msg2 = "אחד או יותר מהשדות אינו תקין:";
      let flag = false;
      let flag2 = false;

      for (var key in values.value) {
        if (values.value[key] === null ||  values.value[key]==="" || values.value[key]?.length == 0) {
          flag = true;
          msg += vluesList[key] + " , ";
        }
      }
      msg = msg.replace(/,\s*$/, "");
      if (flag == true) {
        console.log("flag is:", flag);
        alert('error','כדי להשלים את מילוי הטופס',msg)
      } else {
        if (!validateEmail(values.value.selectedEmail)) {
          flag2 = true;
          msg2 += "כתובת מייל אינה תקינה" + " , ";
        }
        if(!validateStock()){
          flag2 = true;
          msg2 += "הכמות חורגת מהמלאי" + " , ";
        }
        if(!signatureValidate()){
          console.log('the canvas is blank');
          flag2 = true;
          msg2+="חסרה חתימה" +",";
        }
        if (flag2) {
          msg2 = msg2.replace(/,\s*$/, "");
          alert('error','נא לוודא',msg2)
        } else {
          if(!validateOrderAmount()){
              alert('error','נא לבחור כמויות מכל פריט','באחד או יותר מהפריטים לא נבחרה כמות')
          }else{
              canvasUrl.value = await getCanvasUrl();
              try {
                const newDocRef = projectFirestore
                  .collection("Applications")
                  .doc("wH3Q9T5lhhJ0PsdpBN1J")
                  .collection("Orders")
                  .doc();
                await newDocRef.set({
                  orderId: newDocRef.id,
                  branche: selectedBranch.value,
                  createdAt: new Date(),
                  idNumber: values.value.idNumber,
                  worker_name: values.value.selectedFullName,
                  worker_role: values.value.selectedRole,
                  items: itemsToUpdate(),
                  owner: values.value.selectedOwner,
                  email: values.value.selectedEmail,
                  signature: url.value,
                  signature_path: filePath.value,
                  status: "open"
                });
                
                // update 
                updateStock()

                await increment('wH3Q9T5lhhJ0PsdpBN1J')

                //send mail
                sendEmailTest()

                values.value = {
                  selectedDate: null,
                  selectedFullName: null,
                  idNumber: null,
                  selectedRole: null,
                  selectedItems: null,
                  selectedSize: null,
                  selectedQnt: null,
                  selectedOwner: null,
                  selectedEmail: null
                };
                showOrders.value = !showOrders.value;

                alert('success','הפרטים עודכנו בהצלחה','הטופס נשלח לאחראי')
                .then(()=>{
                    emit('close') 
                })  
              } catch (err) {
                console.log(err);
          }


          }
        }
      }
    };

    const getCanvasUrl = () => {
      return new Promise(resolve => {
        const signPadElement = document.getElementById("cnvinput");
        signPadElement.toBlob(async function(blob) {
          await uploadImage(
            blob,
            values.value.selectedFullName
          );
          resolve(url.value);
        });
      });
    };
    const signatureValidate=()=>{
      const cnv = document.getElementById('cnvinput');
      return isDrawnOn(cnv)
    }

    const validateEmail = email => {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email.toLowerCase());
    };

    const validateStock=()=>{
      const data = itemsToUpdate()
      for(let i=0; i<data.length; i++){
          if(Number(data[i].quantity)>Number(data[i].stock)){
              console.log(data[i]);
              return false
          }
      }
      return true
    }


    const updateStock=()=>{
        itemsToUpdate().forEach(async item=>{
            try{   
                await projectFirestore
                .collection("Applications")
                .doc("wH3Q9T5lhhJ0PsdpBN1J")
                .collection('Products')
                .doc(item.barkod.toString())
                .update({
                    stock: item.stock-item.quantity
                })
            }catch(err){
                console.log(err.message);
                error.value=true
            }
        }) 
    }


    function sendEmailTest() {
         projectFunctions.httpsCallable('send_email')({
              html: document.getElementById("form-email").innerHTML,
              destinations:["rosman.mate@gmail.com","mosescorcias@gmail.com"],
              from:"נפתחה הזמנת ביגוד חדשה",
              title:`הזמנת ביגוד לסניף ${selectedBranch.value},עבור: ${values.value.selectedFullName}`
          })
    }

    onMounted(() => {
      projectFirestore
        .collection("Applications")
        .doc("wH3Q9T5lhhJ0PsdpBN1J")
        .collection("Products")
        .get()
        .then(docs => {
          docs.forEach(doc => {
            products.value.push(doc.data());
          });
        });
    });

    return {
      canvasUrl,
      branchesTypes,
      values,
      products,
      items,
      roleList,
      showChooseBranch,
      branches,
      handleShowNewOrder,
      selectedBranch,
      showOrders,
      whenDialogClose,
      handleSubmit,
      currentOrder,
      quantities,
      validateStock,
      specificCurrentOrder,
    };
  }
};
</script>

<style scoped>

.items_and_qnt{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.send-btn {
  margin-top: 20px;
  width: 100%;
  text-align: center;
}
.fields {
  width: 100%;
}
.fields .field {
  margin-top: 15px;
  display: grid;
  grid-template-columns: 0.7fr 2fr;
  align-items: center;
}
.items_and_qnt{
  display: grid;
  grid-template-columns:repeat(3,1fr)
}
input[type="date"] {
  height: 35px;
  border: 0.5px solid lightgrey;
  border-radius: 3px;
}
input[type="date"]:hover {
  border: 1.1px solid lightblue;
}
input[type="date"]:focus {
  outline: none;
  border: 1.1px solid lightblue;
}
.introduction {
  line-height: 1.5;
}
.container-bigud-order {
  width: 100%;
  height: 90vh;
}
.choose-branch {
  color: #fff;
  width: 100%;
  height: 80vh;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.product-line {
  display: flex;
  width: 100%;
  gap: 5px;
  align-items: center;
}

.product-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 25vh;
  overflow: hidden;
  overflow-y: auto;
  gap: 15px;
  border-radius: 3px;
  margin-top: 20px;
}

.stock-amount {
  text-align: center;
  padding-left: 1rem;
}

.headlines {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  font-weight: bold;
}

.notes textarea {
  width: 100%;
  height: 2rem;
  font-size: 25px;
}
.notes textarea::placeholder {
  font-size: 20px;
  margin: 10px;
}

.filter-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  direction: rtl;
}

.contianer-confirmation {
  height: 60vh;
  overflow: hidden;
}
.products-before-update {
  height: 50vh;
  overflow-y: auto;
}

table {
  margin: 1rem 0;
  font-size: 1.1rem;
  border-collapse: collapse;
  width: 100%;
}

table td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  text-align: center;
}

table td:last-child,
th:last-child {
  color: red;
}
table tr:nth-child(even) {
  background-color: #dddddd;
}
.dialog-footer {
  display: flex;
  justify-content: space-evenly;
}
</style>
